import React from 'react';
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import {siteVisits} from "../data";
import {Typography} from "@mui/material";

const SiteVisitsGraph = ({ impressions, views }) => {
    const data = impressions
        .map((item, index) => ({
            date: item.end_time.split('T')[0],
            impressions: item.value,
            views: views[index].value
        }))
    return (
        <React.Fragment>
            <Typography variant={"h5"}>Site Visits</Typography>
            <ResponsiveContainer width="100%" height={120}>
                <AreaChart data={data} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                    <Tooltip
                        labelStyle={{color: 'black'}}
                        labelFormatter={function (value) {
                            return `Month: ${value}`;
                        }}
                        cursor={false}
                    />
                    <XAxis dataKey="date" hide/>
                    <Area type="monotone" dataKey="impressions" stackId="1" stroke="#0795F4" fillOpacity={1}
                          fill="#0795F4"/>
                    <Area type="monotone" dataKey="views" stackId="1" stroke="#9BE7FD" fillOpacity={1} fill="#9BE7FD"/>
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>

    );
};

export default SiteVisitsGraph;
