import React from "react";
import Auth from "./middleware/Auth";
import Home from "../pages/home";
import Login from "../pages/login";
import Accounts from "../pages/accounts";
import Publish from "../pages/publish";
import Drafts from "../pages/drafts";
import Topics from "../pages/topics";
import Materials from "../pages/materials";
import Schedule from "../pages/schedule";
import Tags from "../pages/tags";

const routes = [
    {
        path: "/",
        element: <Auth><Home /></Auth>
    },
    {
        path: "/accounts",
        element: <Auth><Accounts /></Auth>
    },
    {
        path: "/schedule",
        element: <Auth><Schedule /></Auth>
    },
    {
        path: "/publish",
        element: <Auth><Publish /></Auth>
    },
    {
        path: "/drafts",
        element: <Auth><Drafts /></Auth>
    },
    {
        path: "/topics",
        element: <Auth><Topics /></Auth>
    },
    {
        path: "/materials",
        element: <Auth><Materials /></Auth>
    },
    {
        path: "/tags",
        element: <Auth><Tags /></Auth>
    },
    {
        path: "/login",
        element: <Login />
    },
];

export default routes;
