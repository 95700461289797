import React from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import useTopics from '../../hooks/useTopics';
import TopicsList from './TopicsList';
import AddTopicForm from './AddTopicForm';

const PublishTable = () => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {t} = useTranslation();
    const {topics, create} = useTopics();

    const handleAddTopic = React.useCallback(async ({ name, keywords }) => {
        await create(name, keywords);
        hideDialog();
    }, [create, hideDialog]);

    const showAddTopicDialog = React.useCallback(() => {
        showDialog({
            title: "Add topic",
            content: <AddTopicForm onSave={handleAddTopic}/>
        })
    }, [showDialog]);

    return (
        <JumboCardQuick
            title={t('topics')}
            action={
                <IconButton onClick={showAddTopicDialog}>
                    <AddIcon/>
                </IconButton>
            }
            wrapperSx={{p: 0}}
        >
            {
                topics.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No topics added</Typography>
                        </Div>
                    )
                    : <TopicsList topics={topics}/>
             }
        </JumboCardQuick>
    );
};

export default PublishTable
