import baseAxios from "axios";

const creator = () => baseAxios.create({
    baseURL: '/api/v1',
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('id_token')
    }
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default creator;
