import React from 'react';
import {ListItemIcon, ListItemText, Typography} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {MessageOutlined} from "@mui/icons-material";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const GeneralInfo = ({ postCount, scheduledPostCount, totalViews, totalComments }) => {
    return (
        <React.Fragment>
            <Typography variant={"h5"}>You Have</Typography>
            <List>
                <ListItemButton
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{minWidth: 32}}>
                        <DescriptionOutlinedIcon fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography>{postCount} Posts</Typography>}/>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{minWidth: 32}}>
                        <CalendarMonthOutlinedIcon fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography>{scheduledPostCount} Scheduled Posts</Typography>}/>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{minWidth: 32}}>
                        <RemoveRedEyeOutlinedIcon fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography>{totalViews} Total Views</Typography>}/>
                </ListItemButton>
                <ListItemButton
                    sx={{
                        px: 0,
                        py: .25,

                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemIcon sx={{minWidth: 32}}>
                        <MessageOutlined fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography>{totalComments} Comments</Typography>}/>
                </ListItemButton>
            </List>
        </React.Fragment>
    );
};

export default GeneralInfo;
