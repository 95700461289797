import React from 'react';
import {Button, Stack, TextField, FormControl, FormControlLabel, InputLabel} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider } from "@mui/x-date-pickers";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {useTranslation} from "react-i18next";

import Select from '../../../shared/Select';
import { upload } from '../../../services/files';
import FileUploader from '../../../shared/FileUploader';

const AddingDialog = ({ onChange }) => {
    const {t} = useTranslation();
    const [state, setState] = React.useState({ name: '', type: '', file: {} })

    const handleSave = React.useCallback(() => {
        onChange(state)
    }, [state, onChange])

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        upload(formData)
            .then((data) => setState({ ...state, file: data.payload }))
            //.catch(e => setNotify({ open: true, message: '上傳檔案失敗' }))
    };

    return (
        <Stack spacing={3}>
            <FormControl>
                <TextField
                    label='名稱'
                    value={state.name}
                    onChange={(e) => setState({ ...state, name: e.target.value})}
                />
            </FormControl>
                <Select
                    label='類型'
                    onChange={(e) => setState({ ...state, type: e.target.value})}
                    options={[{ name: t('image'), value: 'image' }, { name: t('video'), value: 'video' }]}
                />
            <FileUploader
                width="100%"
                onChange={handleUploadFile}
            />
            <Button variant='contained' onClick={handleSave}>Save</Button>
        </Stack>
    );
};

export default AddingDialog;
