import React from 'react';
import {Grid} from "@mui/material";

import TagsTable from "./TagsTable";

const Accounts = () => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12}>
                <TagsTable/>
            </Grid>
        </Grid>
    );
};

export default Accounts;
