import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import GeneralInfo from "./components/GeneralInfo";
import SiteVisitsGraph from "./components/SiteVisitsGraph";
import SiteAudienceInfo from "./components/SiteAudienceInfo";

import FacebookIcon from '../../shared/icons/Facebook';
import InstgramIcon from '../../shared/icons/Instagram';
import Twittercon from '../../shared/icons/Twitter';
import LinkedInIcon from '../../shared/icons/LinkedIn';

import usePageDashboard from "../../hooks/usePageDashboard";

const Icons = {
    facebook: <FacebookIcon fontSize={24}/>,
    instagram: <InstgramIcon fontSize={24}/>,
    twitter: <Twittercon fontSize={24}/>,
    linkedin: <LinkedInIcon fontSize={24}/>,
}
const InfoCard = ({ id, platform, name }) => {
    const {t} = useTranslation();
    const { data, error } = usePageDashboard(id);

    const postCount = (data && data.posts) ? data.posts.length : 0;
    const totalViews = (data && data.daily_views) ? data.daily_views.reduce((a, b) => a + b.value, 0) : 0;

    return (
        <JumboCardQuick
            title={
                <Stack direction="row" gap={1}>
                    { Icons[platform] }
                    <Typography variant="h3">{name}</Typography>
                </Stack>
            }
            wrapperSx={{pt: 1}}
        >
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={3}>
                    <GeneralInfo
                        postCount={postCount}
                        scheduledPostCount={0}
                        totalViews={totalViews}
                        totalComments={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{order: {lg: 3}}}>
                    <SiteAudienceInfo/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SiteVisitsGraph impressions={data.daily_impressions || []} views={data.daily_views || []}/>
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
};

export default InfoCard;
