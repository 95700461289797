import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function useMaterials() {
  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { data, mutate } = useSWR(`/users/${user.id}/materials`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/materials`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
  );

  async function add(doc) {
    const payload = {
      name: doc.name,
      type: doc.type,
      contents: [{
        type: doc.type,
        source: doc.file
      }]
    }

    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/materials`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('id_token')
      },
      data: payload
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  async function remove(id) {
    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/materials/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  return {
    materials: data?.data || [],
    add,
    remove,
    isLoading,
  };
}
