import React from 'react';
import {List} from "@mui/material";
import Item from "./Item";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const TopicsList = ({topics = []}) => {
    const onAction = (action, item) => {
        console.log(action, item)
    };

    return (
        <JumboScrollbar autoHeight autoHeightMax="100vh" autoHide autoHideDuration={200} autoHideTimeout={500}>
            <List disablePadding>
                {
                    topics.map((item, index) => (
                        <Item item={item} key={index} onAction={onAction}/>
                    ))
                }
            </List>
        </JumboScrollbar>
    );
};
/* Todo prop define */
export default TopicsList;
