import {Twitter} from "@mui/icons-material";

const Icon = ({fontSize = 36}) => {
  return (
      <Twitter
            sx={{
                cursor: 'pointer',
                fontSize: fontSize,
                bgcolor: 'common.white',
                color: '#1da1f2'
            }}
      />
  )
}

export default Icon
