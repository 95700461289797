import React from 'react';
import {
    Chip,
    Avatar,
} from "@mui/material";
import Div from "@jumbo/shared/Div";

import FaceBookIcon from '../../shared/icons/Facebook';
import InstagramIcon from '../../shared/icons/Instagram';
import TwitterIcon from '../../shared/icons/Twitter';
import LinkedInIcon from '../../shared/icons/LinkedIn';
import Select from '../../shared/Select';

const Icons = {
    facebook: <FaceBookIcon fontSize={24}/>,
    instagram: <InstagramIcon fontSize={24}/>,
    twitter: <TwitterIcon fontSize={24}/>,
    linkedin: <LinkedInIcon fontSize={24}/>,
}

const TokenSelector = ({ tokens, selectedTokens, onChange }) => {
    return (
        <Select
            label="Publish to: "
            multiple
            value={selectedTokens}
            options={tokens.map(t => ({ name: `${t.platform} - ${t.user_name}`, value: t.id })) }
            onChange={onChange}
            renderValue={(selected) => (
                <Div sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                    {selected.map((value) => (
                        <Chip
                            key={value}
                            label={tokens.filter(t => t.id === value).pop()?.user_name}
                            avatar={
                                <Avatar>{Icons[tokens.filter(t => t.id === value).pop()?.platform]}</Avatar>
                            }
                        />
                    ))}
                </Div>
            )}
            sx={{
                width: '60%',
            }}
        />
    );
};

export default TokenSelector;
