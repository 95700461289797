import React from 'react';
import {
    Chip,
} from "@mui/material";
import Div from "@jumbo/shared/Div";

import Select from '../../shared/Select';
import useTags from '../../hooks/useTags';

const TokenSelector = ({ value, onChange }) => {
    const {tags} = useTags();

    return (
        <Select
            label="Tags"
            multiple
            value={value}
            options={tags.map(tag => ({value: tag.id, name: tag.name}))}
            onChange={onChange}
            renderValue={(selected) => (
                <Div sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                    {selected.map((tid) => (
                        <Chip
                            key={tid}
                            label={tags.filter(t => t.id === tid).pop()?.name}
                            style={{ color: 'white', backgroundColor: tags.filter(t => t.id === tid).pop()?.color }}
                        />
                    ))}
                </Div>
            )}
            sx={{
                width: '60%',
            }}
        />
    );
};

export default TokenSelector;
