import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function usePublish() {
  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { data, error, mutate } = useSWR(`/users/${user.id}/publish`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/publish`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
  );

  function create(formData) {
    const doc = {
      content: formData.content,
      platforms: formData.platforms,
      tags: formData.tags || [],
    }
    if (formData.scheduleType === 'schedule') {
      doc.scheduled_at = Math.floor(formData.publishAt.getTime() / 1000);
    }
    if (formData.file) {
      doc.file = formData.file;
    }

    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/publish`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('id_token')
      },
      data: doc
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  return {
    publish: data?.data || [],
    reload: mutate,
    error,
    create,
    isLoading,
  };
}
