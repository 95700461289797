import React from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";

import useDrafts from '../../../hooks/useDrafts';
import DraftsList from './DraftsList';

const DraftsTable = () => {
    const {t} = useTranslation();
    const {drafts} = useDrafts();

    return (
        <JumboCardQuick
            title={t('drafts')}
            wrapperSx={{p: 0}}
        >
            {
                drafts.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No drafts added</Typography>
                        </Div>
                    )
                    : <DraftsList items={drafts}/>
             }
        </JumboCardQuick>
    );
};

export default DraftsTable
