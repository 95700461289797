import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

export const menuItems = [
    {
        icon: <EditIcon size={20}/>,
        title: "Edit",
        value: "EDIT"
    },
    {
        icon: <CloseIcon size={20}/>,
        title: "DELETE",
        value: "DELETE"
    },
];
