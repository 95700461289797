import React from 'react';
import {Grid} from "@mui/material";

import PublishTable from './PublishTable';
import Previews from './Previews';
import Editor from './Editor';

const Publish = () => {
    return (
            <Grid container spacing={3.75}>
                <Grid item xs={12}>
                    <PublishTable />
                </Grid>
                <React.Fragment>
                    <Grid item xs={12} lg={8}>
                        <Editor />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Previews/>
                    </Grid>
                </React.Fragment>
            </Grid>
    );
};

export default Publish;
