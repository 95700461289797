import React from 'react';
import {
    Box,
    ListItem,
    ListItemText,
    Typography,
    Divider,
} from "@mui/material";

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import {menuItems} from "./data";

const Item = ({item, onAction}) => {
    return (
        <React.Fragment>
            <Divider component="li"/>
            <ListItem sx={{px: 3, py: 1.25}}>
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography variant='h5' mb={.5}>{item.name}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '30%' }}
                    primary={
                         <Box
                            sx={{
                                width: 24,
                                height: 24,
                                borderRadius: '20%',
                                backgroundColor: item.color,
                            }}
                         />
                    }
                />
                <ListItemText
                    sx={{flexGrow: 0}}
                    primary={
                        <JumboDdMenu
                            onClickCallback={(action) => onAction(action, item)}
                            menuItems={menuItems}
                        />
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};
/* Todo prop define */
export default Item;
