import React, {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {SnackbarProvider} from "notistack";
import AppProvider from "./AppProvider";
import AuthProvider from "./AuthProvider";
import {config} from "./config/main";
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import PublishProvider from "./pages/publish/provider";

function App() {
    return (
            <BrowserRouter>
            <AppProvider>
            <AuthProvider>
                <PublishProvider>
                <JumboApp activeLayout={config.activeLayout}>
                    <JumboTheme init={config.theme}>
                            <JumboDialogProvider>
                                <JumboDialog/>
                                <SnackbarProvider
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    maxSnack={3}>

                                    <AppLayout>
                                        <Suspense
                                            fallback={
                                                <Div
                                                    sx={{
                                                        display: 'flex',
                                                        minWidth: 0,
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <CircularProgress sx={{m: '-40px auto 0'}}/>
                                                </Div>
                                            }
                                        >
                                            <AppRoutes/>
                                        </Suspense>
                                    </AppLayout>

                                </SnackbarProvider>
                            </JumboDialogProvider>
                    </JumboTheme>
                </JumboApp>
                </PublishProvider>
            </AuthProvider>
            </AppProvider>
            </BrowserRouter>
    );
}

export default App;
