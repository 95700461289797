import React from 'react';
import {Stack} from "@mui/material";

import { usePublish } from '../provider';
import FacebookPreview from './FacebookPreview';
import InstagramPreview from './InstagramPreview';
import LinkedInPreview from './LinkedInPreview';
import TwitterPreview from './TwitterPreview';

const Previews = () => {
    const { isAdding, selectedTokens, content, file } = usePublish()
    if (!isAdding) {
        return null
    }

    const platforms = selectedTokens.map(t => t.platform)

    return (
        <Stack spacing={2}>
            {
                platforms.includes('facebook') &&
                <FacebookPreview
                    content={content}
                    file={file}
                    token={selectedTokens.find(t => t.platform === 'facebook')}
                />
            }
            {platforms.includes('instagram') && <InstagramPreview content={content} file={file}/>}
            {platforms.includes('linkedin') && <LinkedInPreview content={content} file={file}/>}
            {
                platforms.includes('twitter') &&
                <TwitterPreview
                    content={content}
                    file={file}
                    token={selectedTokens.find(t => t.platform === 'twitter')}
                />
            }
        </Stack>
    );
};

export default Previews;
