import React from 'react';

import * as _ from "./actions";

export const PublishContext = React.createContext({});
export const usePublish = () => React.useContext(PublishContext);

const initState = {
    isAdding: false,
    selectedTokens: [],
    content: '',
    file: null,
    scheduleType: 'now',
    publishAt: new Date(),
    tags: [],
}
const reducer = (state, action) => {
    switch (action.type) {
        case _.SELECT_TOKENS:
            return {
                ...state,
                selectedTokens: action.tokens,
            };
        case _.SET_CONTENT:
            return {
                ...state,
                content: action.content,
            };
        case _.SET_PUBLISH_AT:
            return {
                ...state,
                publishAt: action.publishAt,
            }
        case _.SET_SCHEDULE_TYPE:
            return {
                ...state,
                scheduleType: action.scheduleType,
            }
        case _.SET_IS_ADDING:
            return {
                ...state,
                isAdding: action.isAdding,
            }
        case _.SET_FILE:
            return {
                ...state,
                file: action.file,
            }
        case _.SET_TAGS:
            return {
                ...state,
                tags: action.tags,
            }
        case _.RESET:
            return { ...initState }
        default:
            return state;
    }

};
const PublishProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, initState);
    const selectTokens = React.useCallback((tokens) => {
        dispatch({type: _.SELECT_TOKENS, tokens: tokens});
    }, [dispatch]);
    const setContent = React.useCallback((content) => {
        dispatch({type: _.SET_CONTENT, content: content});
    }, [dispatch]);
    const setPublishAt = React.useCallback((publishAt) => {
        dispatch({type: _.SET_PUBLISH_AT, publishAt: publishAt});
    }, [dispatch]);
    const setScheduleType = React.useCallback((scheduleType) => {
        dispatch({type: _.SET_SCHEDULE_TYPE, scheduleType: scheduleType});
    }, [dispatch]);
    const reset = React.useCallback(() => {
        dispatch({type: _.RESET});
    }, [dispatch]);
    const setIsAdding = React.useCallback((isAdding) => {
        dispatch({type: _.SET_IS_ADDING, isAdding: isAdding});
    }, [dispatch]);
    const setFile = React.useCallback((file) => {
        dispatch({type: _.SET_FILE, file: file});
    }, [dispatch]);
    const setTags = React.useCallback((tags) => {
        dispatch({type: _.SET_TAGS, tags: tags});
    }, [dispatch]);

    const contextValue = {
        ...state,
        selectTokens,
        setContent,
        setPublishAt,
        setScheduleType,
        setIsAdding,
        setFile,
        setTags,
        reset,
    }
    return (
        <PublishContext.Provider value={contextValue}>
            {children}
        </PublishContext.Provider>
    )
}
export default PublishProvider;
