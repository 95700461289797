import {Instagram} from "@mui/icons-material";

const Icon = ({ fontSize = 36 }) => {
  return (
      <Instagram
            sx={{
                cursor: 'pointer',
                fontSize: fontSize,
                bgcolor: 'common.white',
                color: '#e1306c'
            }}
      />
  )
}

export default Icon
