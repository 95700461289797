import React from 'react';
import {Grid} from "@mui/material";

import Table from "./Table";

const Accounts = () => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12}>
                <Table/>
            </Grid>
        </Grid>
    );
};

export default Accounts;
