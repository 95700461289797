import {Facebook} from "@mui/icons-material";

const Icon = ({ fontSize = 36 }) => {
  return (
      <Facebook
            sx={{
                cursor: 'pointer',
                fontSize: fontSize,
                bgcolor: 'common.white',
                color: '#3b5998'
            }}
      />
  )
}

export default Icon
