function upload(formData, namespace = 'chatpr') {
  return fetch(`/files?namespace=${namespace}`, {
    method: 'POST',
    body: formData,
  })
  .then(res => {
    if (res.status >= 400) {
      return Promise.reject(res.json());
    }

    return res.json()
  })
}

export { upload }
