import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function useTokens() {
  const { user } = useAuth();

  const { data } = useSWR(`/users/${user.id}/tokens`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/tokens`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
  );

  return {
    tokens: data?.data || []
  };
}
