import React from 'react';
import {List} from "@mui/material";
import PublishItem from "./PublishItem";

const PublishList = ({publish = [], tags = []}) => {
    const onAction = (action, item) => {
        console.log(action, item)
    };

    return (
        <List disablePadding>
            {
                publish.map((item, index) => (
                    <PublishItem item={item} key={index} onAction={onAction} tags={tags}/>
                ))
            }
        </List>
    );
};
/* Todo prop define */
export default PublishList;
