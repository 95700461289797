import React from 'react';
import {Button, Stack, TextField, RadioGroup, Radio, FormControl, FormControlLabel} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider } from "@mui/x-date-pickers";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";

const SettingDialog = ({ scheduleType, publishAt, onChange }) => {
    const [state, setState] = React.useState({ scheduleType, publishAt })

    const handleSave = React.useCallback(() => {
        onChange(state)
    }, [state, onChange])

    return (
        <Stack spacing={3}>
            <FormControl>
                <RadioGroup
                    value={state.scheduleType}
                    onChange={(e) => setState({ ...state, scheduleType: e.target.value})}
                    row
                >
                    <FormControlLabel value="now" control={<Radio />} label="Post now" />
                    <FormControlLabel value="schedule" control={<Radio />} label="Schedule" />
                </RadioGroup>
            </FormControl>
            {
                state.scheduleType === 'schedule' &&
                <LocalizationProvider  dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Schedule"
                        value={state.publishAt}
                        onChange={(value) => setState({ ...state, publishAt: value })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            }
            <Button variant='contained' onClick={handleSave}>Save</Button>
        </Stack>
    );
};

export default SettingDialog;
