import React from 'react';
import Stack from '@mui/material/Stack';

import useTokens from '../../hooks/useTokens';

import InfoCard from "./InfoCard";

const WelcomeSummary = () => {
    const { tokens } = useTokens();

    return (
        <Stack gap={4}>
            {
                tokens.map((token, index) => (
                    <InfoCard key={token.id} id={token.id} platform={token.platform} name={token.user_name} />
                ))
            }
        </Stack>
    );
};

export default WelcomeSummary;
