import React from 'react';
import {IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import useTags from '../../../hooks/useTags';
import TagsList from './TagsList';
import AddTagForm from './AddTagForm';

const PublishTable = () => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {t} = useTranslation();
    const {tags, create, update, remove} = useTags();

    const handleAddTag = React.useCallback(async ({ name, color }) => {
        await create(name, color);
        hideDialog();
    }, [create, hideDialog]);
    const handleUpdateTag = React.useCallback((id) => async ({ name, color }) => {
        await update(id, name, color);
        hideDialog();
    }, [hideDialog, update]);
    const handleDelete = React.useCallback((id) => async () => {
        await remove(id);
        hideDialog();
    }, [hideDialog, remove])

    const showAddTagDialog = React.useCallback((id, name, color) => {
        if(id && name && color) {
            showDialog({
                title: "Add tag",
                content: <AddTagForm
                    defaultName={name}
                    defaultColor={color}
                    onSave={handleUpdateTag(id)}
                />
            })
        } else {
            showDialog({
                title: "Add tag",
                content: <AddTagForm onSave={handleAddTag}/>
            })
        }
    }, [showDialog, handleAddTag, handleUpdateTag]);
    const handleActions = (action, item) => {
        if (action.value === 'EDIT') {
            showAddTagDialog(item.id, item.name, item.color);
        }
        if (action.value === 'DELETE') {
            showDialog({
                title: 'Delete Tag',
                content: 'Are you sure you want to delete this tag?',
                variant: 'confirm',
                onYes: handleDelete(item.id)
            })
        }
    }

    return (
        <JumboCardQuick
            title={t('tags')}
            action={
                <IconButton onClick={() => showAddTagDialog()}>
                    <AddIcon/>
                </IconButton>
            }
            wrapperSx={{p: 0}}
        >
            {
                tags.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No tags added</Typography>
                        </Div>
                    )
                    : <TagsList tags={tags} onAction={handleActions}/>
             }
        </JumboCardQuick>
    );
};

export default PublishTable
