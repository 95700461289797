import {LinkedIn} from "@mui/icons-material";

const Icon = ({ fontSize = 36 }) => {
  return (
      <LinkedIn
            sx={{
                cursor: 'pointer',
                fontSize: fontSize,
                bgcolor: 'common.white',
                color: '#0077b5',
            }}
      />
  )
}

export default Icon
