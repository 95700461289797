import React from 'react';
import {Grid, Chip, Stack} from "@mui/material";

import usePublishAPI from '../../hooks/usePublish';
import Calendar from '../../shared/Calendar';
import FacebookIcon from '../../shared/icons/Facebook';
import InstgramIcon from '../../shared/icons/Instagram';
import Twittercon from '../../shared/icons/Twitter';
import LinkedInIcon from '../../shared/icons/LinkedIn';

const Icons = {
    facebook: FacebookIcon,
    instagram: InstgramIcon,
    twitter: Twittercon,
    linkedin: LinkedInIcon,
}
const colors = {
    'DONE': 'success',
    'FAILED': 'error',
}

const EventCard = ({event}) => {
    const Icon = Icons[event.platform]
    const color = colors[event.status] || 'default'
    return (
        <Stack direction="row" spacing={1} sx={{ pl: 1 }}>
            <Icon fontSize={24}/>
            <Chip
                label={event.status}
                size='small'
                variant='outlined'
                color={color}
            />
        </Stack>
    );
}

const Publish = () => {
    const {publish} = usePublishAPI();
    const events = publish.map((item) => ({
        title: item.platform,
        platform: item.platform,
        content: item.content,
        status: item.status,
        start: new Date(item.scheduled_at * 1000),
        end: new Date(item.scheduled_at * 1000 + 3600000),
    }))

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12}>
                <Calendar
                    events={events}
                    EventComponent={EventCard}
                />
            </Grid>
        </Grid>
    );
};

export default Publish;
