import React from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    Divider,
} from "@mui/material";
import moment from 'moment'

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import {menuItems} from "./data";

const Item = ({item, onAction}) => {
    return (
        <React.Fragment>
            <Divider component="li"/>
            <ListItem sx={{px: 3, py: 1.25}}>
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography variant='h5' mb={.5}>{item.name}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '50%' }}
                    primary={<Typography>{item.rule[0].tags}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography>{moment(item.updated_at).format('YYYY-MM-DD HH:mm')}</Typography>}
                />
                <ListItemText
                    sx={{flexGrow: 0}}
                    primary={
                        <JumboDdMenu
                            onClickCallback={(action) => onAction(action, item)}
                            menuItems={menuItems}
                        />
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};
/* Todo prop define */
export default Item;
