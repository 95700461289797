import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from "@mui/material";

export default function Select({
  label,
  name,
  value,
  onChange,
  options,
  inputProps,
  fullWidth = true,
  renderValue,
  ...props
}) {
  return (
    <FormControl fullWidth {...props}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        renderValue={renderValue}
        multiple={props.multiple}
      >
        {options?.map((opt, index) => (
          <MenuItem value={opt.value} disabled={!!opt.disabled} key={index}>
            {opt.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
