import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";

import Facebook from "../../icons/Facebook";
import Instagram from "../../icons/Instagram";
import Twitter from "../../icons/Twitter";
import LinkedIn from "../../icons/LinkedIn";

const AccountsSum = ({ count = 0, platform = 'facebook' }) => {
    let Icon = Facebook;
    if (platform === 'instagram') Icon = Instagram;
    if (platform === 'twitter') Icon = Twitter;
    if (platform === 'linkedin') Icon = LinkedIn;

    const colors = {
        facebook: '#3b5998',
        instagram: '#e1306c',
        twitter: '#1da1f2',
        linkedin: '#0077b5',
    }

    return (
        <Card sx={{height: 115, backgroundImage: `linear-gradient(135deg, ${colors[platform]}, #843CF6)`}}>
            <CardActions disableSpacing sx={{p: 0, alignItems: 'stretch', height: '100%'}}>
                <Div sx={{
                    display: 'flex',
                    width: 126,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    backgroundColor: 'common.white',
                    borderRadius: '50%',
                    outline: 'solid 10px rgba(255, 255, 255, 0.2)',
                    margin: '0 10px 0 -60px'
                }}>
                    <Div sx={{
                        display: 'flex',
                        minWidth: 56,
                        height: 56,
                        mr: '6px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Icon
                            sx={{
                                cursor: 'pointer',
                                fontSize: 36,
                                bgcolor: 'common.white',
                                color: colors[platform],
                            }}
                         />
                    </Div>

                </Div>
                <CardContent sx={{p: 2.5, flex: 1, alignSelf: 'center'}}>
                    <Typography variant={"h4"} color={"common.white"}>{count}</Typography>
                    <Typography variant={"h5"} color={"common.white"} mb={0}>Accounts</Typography>
                </CardContent>
            </CardActions>
        </Card>
    );
};

export default AccountsSum;
