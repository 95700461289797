import React from 'react';
import {List} from "@mui/material";

import Item from "./Item";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useMaterials from '../../../hooks/useMaterials';

const DraftsList = ({items = []}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {remove} = useMaterials();

    const onAction = (action, item) => {
        if (action.value === 'DELETE') {
            showDialog({
                title: 'Delete Material',
                content: 'Are you sure you want to delete this material?',
                variant: 'confirm',
                onYes: handleDelete(item.id)
            })
        }
    };
    const handleDelete = (id) => () => {
        remove(id)
            .then(hideDialog)
    }

    return (
        <JumboScrollbar autoHeight autoHeightMax="100vh" autoHide autoHideDuration={200} autoHideTimeout={500}>
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexWrap: 'wrap'
                }}
            >
                {
                    items.map((item, index) => (
                        <Item item={item} key={index} onAction={onAction}/>
                    ))
                }
            </List>
        </JumboScrollbar>
    );
};
/* Todo prop define */
export default DraftsList;
