import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function useDrafts() {
  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { data, mutate } = useSWR(`/users/${user.id}/drafts`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/drafts`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
  );

  function create(content, platforms) {
    const doc = { content, platforms };

    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/drafts`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('id_token')
      },
      data: doc
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  function remove(id) {
    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/drafts/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  return {
    drafts: data?.data || [],
    create,
    remove,
    isLoading,
  };
}
