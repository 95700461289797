import creator from "./config";

async function fetchTopNews(topic_id, limit = 3) {
  return creator()({
    method: 'GET',
    url: `topics/${topic_id}/news?limit=${limit}`
  })
  .then(_ => _.data)
}

export default { fetchTopNews };
