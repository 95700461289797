import {
    Avatar,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Stack,
    IconButton,
    Button,
} from "@mui/material";
import {MoreHorizOutlined} from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

function FacebookPreview({ content, file, token }) {
    return (
        <Card>
            <CardHeader
                avatar={<Avatar alt="Apple" src="" />}
                title={token.user_name}
                subheader={
                    <Stack direction="row">
                        <p style={{ margin: 0 }}>{`由 ${token.user_name} 發佈`}</p>
                        <HelpIcon sx={{ fontSize: 16, ml: 1 }}/>
                        <p style={{ margin: 0, marginLeft: 8 }}>·</p>
                        <p style={{ margin: 0, marginLeft: 8 }}>1小時</p>
                    </Stack>
                }
                action={<MoreHorizOutlined />}
            />
            <CardContent>
                <Stack>
                    <p>{content}</p>
                    {
                        file && file.type === 'image' &&
                        <div style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
                            <img
                                width="100%"
                                alt="uploaded file"
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                            />
                        </div>
                    }
                    {
                        file && file.type === 'video' &&
                        <div style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
                            <video
                                width="100%"
                                alt="uploaded file"
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                            />
                        </div>
                    }
                </Stack>
            </CardContent>
            <CardActions style={{ display: 'flex', alignItems: 'space-between', justifyContent: 'space-between' }}>
                <Button startIcon={<ThumbUpAltOutlinedIcon/>} style={{ color: '#65676b' }}>
                    讚
                </Button>
                <Button startIcon={<ModeCommentOutlinedIcon />} style={{ color: '#65676b' }}>
                    留言
                </Button>
                <Button startIcon={<ModeCommentOutlinedIcon />} style={{ color: '#65676b' }}>
                    分享
                </Button>
            </CardActions>
        </Card>
    )
}

export default FacebookPreview;
