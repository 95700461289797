import React from 'react';
import {List} from "@mui/material";
import AccountItem from "./AccountItem";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const AccountsList = ({accounts = []}) => {
    const onAction = (action, item) => {
        console.log(action, item)
    };

    return (
        <JumboScrollbar autoHeight autoHeightMin={360} autoHide autoHideDuration={200} autoHideTimeout={500}>
            <List disablePadding>
                {
                    accounts.map((item, index) => (
                        <AccountItem item={item} key={index} onAction={onAction}/>
                    ))
                }
            </List>
        </JumboScrollbar>
    );
};
/* Todo prop define */
export default AccountsList;
