import React from 'react';
import {Box, TextField, Button} from "@mui/material";

import ColorPicker from '../../../shared/ColorPicker';

const AddTagsForm = ({ onSave, defaultName, defaultColor = '#eb5a46' }) => {
    const [name, setName] = React.useState(defaultName);
    const [color, setColor] = React.useState(defaultColor);

    const handleSave = () => {
        onSave({ name, color });
    }
    return (
        <Box component={'form'}
            sx={{
                '& > .MuiFormControl-root': {
                    marginBottom: 2
                }
            }}
        >
            <TextField
                fullWidth
                placeholder={"Name"}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <ColorPicker
                onChange={setColor}
                selectedColor={color}
            />
            <Button sx={{ mt: 2, float: 'right' }}variant={"contained"} onClick={handleSave}>Save</Button>
        </Box>
    )
}

export default AddTagsForm;
