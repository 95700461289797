import React from 'react';
import {Typography, IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";

import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";

import useMaterials from '../../../hooks/useMaterials';
import MaterialsList from './MaterialsList';
import AddingDialog from './AddingDialog';

const DraftsTable = () => {
    const {t} = useTranslation();
    const {showDialog, hideDialog} = useJumboDialog();
    const {materials, add} = useMaterials();

    const tglAdding = () => {
        showDialog({
            title: t('materials'),
            content: <AddingDialog
                onChange={handleAddMaterial}
            />,
        })
    }
    const handleAddMaterial = ({ name, type, file }) => {
        add({ name, type, file })
            .then(_ => hideDialog())
    }

    const items = materials.filter(m => ['image', 'video'].includes(m.type))

    return (
        <JumboCardQuick
            title={t('materials')}
            wrapperSx={{p: 0}}
            action={
                <IconButton onClick={tglAdding}>
                    <AddIcon/>
                </IconButton>
            }
        >
            {
                items.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No materials added</Typography>
                        </Div>
                    )
                    : <MaterialsList items={items}/>
             }
        </JumboCardQuick>
    );
};

export default DraftsTable
