import React from 'react';
import {Box, TextField, Button} from "@mui/material";

const AddTopicForm = ({ onSave }) => {
    const [name, setName] = React.useState('');
    const [keywords, setKeywords] = React.useState('');

    const handleSave = () => {
        onSave({ name, keywords });
    }
    return (
        <Box component={'form'}
            sx={{
                '& > .MuiFormControl-root': {
                    marginBottom: 2
                }
            }}
        >
            <TextField
                fullWidth
                placeholder={"Name"}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                fullWidth
                multiline
                placeholder={"Keywords"}
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
            />
            <Button sx={{ float: 'right' }}variant={"contained"} onClick={handleSave}>Save</Button>
        </Box>
    )
}

export default AddTopicForm;
