import React from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../../../AuthProvider";

const Auth = ({children}) => {
    const {isAuthenticated} = useAuth()

    return isAuthenticated
        ? children
        : <Navigate to='/login' />
};

export default Auth;
