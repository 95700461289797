import React from 'react';
import {List} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Item from "./Item";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {usePublish} from '../../publish/provider';
import useDrafts from '../../../hooks/useDrafts';

const DraftsList = ({items = []}) => {
    const navigate = useNavigate();
    const {showDialog, hideDialog} = useJumboDialog();
    const {setIsAdding, setContent, selectTokens} = usePublish();
    const {remove} = useDrafts();

    const onAction = (action, item) => {
        if(action.value === 'EDIT') {
            navigate('/publish')
            setIsAdding(true)
            setContent(item.content)
            selectTokens(item.platforms.map(p => ({ 
                id: p.token_id,
                platform: p.type,
                user_name: p.user_name,
            })))
        }
        if (action.value === 'DELETE') {
            showDialog({
                title: 'Delete Draft',
                content: 'Are you sure you want to delete this draft?',
                variant: 'confirm',
                onYes: handleDelete(item.id)
            })
        }
    };
    const handleDelete = (id) => () => {
        remove(id)
            .then(hideDialog)
    }

    return (
        <JumboScrollbar autoHeight autoHeightMax="100vh" autoHide autoHideDuration={200} autoHideTimeout={500}>
            <List disablePadding>
                {
                    items.map((item, index) => (
                        <Item item={item} key={index} onAction={onAction}/>
                    ))
                }
            </List>
        </JumboScrollbar>
    );
};
/* Todo prop define */
export default DraftsList;
