import React from 'react';
import {useNavigate} from "react-router-dom";
import authService from './services/auth';

const AuthContext = React.createContext();

const AuthProvider = ({children}) => {
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search);
    const payload = parseToken(urlParams.get('token') || localStorage.getItem("id_token"))
    const isAuthenticated = payload && payload.exp && (Date.now() / 1000) < payload.exp

    const [state, setState] = React.useState({
        isAuthenticated,
        user: payload,
        isLoading: false,
        error: null,
    });
    
    if (urlParams.get('token')) {
        localStorage.setItem('id_token', urlParams.get('token'))
        clearSearchParams()
    }

    const login = React.useCallback((email, password) => {
        setState(s => ({ ...s, isLoading: true, error: null }))
        authService.login(email, password)
            .then(res => {
                localStorage.setItem('id_token', res.data.token)
                setState({ user: res.data.payload, isAuthenticated: true, isLoading: false, error: null })
                navigate('/')
            })
            .catch(_ => setState({ error: 'Invalid credentials', isLoading: false }))
    }, [setState, navigate])
    const logout = React.useCallback(() => {
        localStorage.removeItem('id_token')
        setState({ user: null, isAuthenticated: false, isLoading: false })
        navigate('/login')
    }, [setState, navigate])

    const contextValue = React.useMemo(() => ({
        ...state,
        login,
        logout,
    }), [state, login, logout]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    return React.useContext(AuthContext);
}

function parseToken(token) {
  try {
    const payload = token.split(".")[1];

    return JSON.parse(atob(payload));
  } catch (e) {
    return {}
  }
}

function clearSearchParams() {
  //window.history.replaceState({}, '', window.location.pathname);
    if (window.location.search) {
        window.location.search = ''
    }
}

export {AuthContext, useAuth};
export default AuthProvider;
