import React from 'react';
import {
    Stack,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    Chip,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment'

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import PlatformAvatarGroup from './PlatformAvatarGroup';

const menuItems = [
    {
        icon: <VisibilityIcon size={20}/>,
        title: "View",
        value: "VIEW"
    },
    {
        icon: <EditIcon size={20}/>,
        title: "Edit",
        value: "EDIT"
    },
    {
        icon: <DeleteIcon size={20}/>,
        title: "DELETE",
        value: "DELETE"
    },
];
const statusChip = {
    'INIT': {
        label: 'INIT',
        color: 'default',
    },
    'DONE': {
        label: 'DONE',
        color: 'success',
    },
    'FAILED': {
        label: 'FAILED',
        color: 'error',
    }
}

const NewRequestItem = ({item, tags, onAction}) => {
    return (
        <React.Fragment>
            <Divider component="li"/>
            <ListItem sx={{px: 3, py: 1.25}}>
                <ListItemText
                    sx={{ flexBasis: '50%' }}
                    primary={
                        <Typography>
                            {
                                item.content.length > 60
                                    ? item.content.slice(0, 60) + '...'
                                    : item.content
                            }
                        </Typography>
                    }
                />
                <ListItemText
                    sx={{ flexBasis: '15%' }}
                    primary={<PlatformAvatarGroup platform={item.platform}/>}
                />
                <ListItemText
                    sx={{ flexBasis: '15%' }}
                    primary={
                        <Typography>
                            <Chip
                                label={statusChip[item.status.toUpperCase()]?.label || item.status}
                                color={statusChip[item.status.toUpperCase()]?.color || 'default'}
                                variant='outlined'
                                size="small"
                            />
                        </Typography>
                    }
                />
                <ListItemText
                    sx={{ flexBasis: '15%' }}
                    primary={<Typography>{moment(item.scheduled_at*1000).format('YYYY-MM-DD HH:mm')}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '15%' }}
                    primary={
                        <Stack direction="row" gap={0.5}>
                        {
                            item.tags?.map(tag => (
                                <Chip
                                    key={tag.id}
                                    label={tags.find(t => t.id === tag)?.name}
                                    style={{
                                        color: 'white',
                                        backgroundColor: tags.find(t => t.id === tag)?.color,
                                    }}
                                />
                            ))
                        }
                        </Stack>
                    }
                />
                <ListItemText
                    sx={{flexGrow: 0}}
                    primary={
                        <JumboDdMenu
                            onClickCallback={(action) => onAction(action, item)}
                            menuItems={menuItems}
                        />
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};
/* Todo prop define */
export default NewRequestItem;
