import React from 'react';
import {Stack, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useTranslation} from "react-i18next";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";

import usePublishAPI from '../../../hooks/usePublish';
import useTags from '../../../hooks/useTags';
import PublishList from './PublishList';
import {usePublish} from '../provider';

const PublishTable = () => {
    const {t} = useTranslation();
    const {isAdding, setIsAdding} = usePublish();
    const {publish, reload} = usePublishAPI();
    const {tags} = useTags();

    const tglAdding = () => {
        setIsAdding(true)
    }

    if (isAdding) return null

    return (
        <JumboCardQuick
            title={t('publish')}
            action={
                <Stack direction="row">
                    <IconButton onClick={reload}>
                        <RefreshIcon/>
                    </IconButton>
                    <IconButton onClick={tglAdding}>
                        <AddIcon/>
                    </IconButton>
                </Stack>
            }
            wrapperSx={{p: 0}}
        >
            {
                publish.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No publish added</Typography>
                        </Div>
                    )
                    : <PublishList publish={publish} tags={tags}/>
             }
        </JumboCardQuick>
    );
};

export default PublishTable
