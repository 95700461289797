import React from 'react';
import {
    Fab,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

function ColorPicker({ onChange, selectedColor }) {
    const COLORS = [
        '#eb5a46',
        '#ff9f1a',
        '#f2d600',
        '#61bd4f',
        '#51e898',
        '#00c2e0',
        '#0079bf',
        '#ff78cb',
        '#c377e0',
    ];
    const [selected, setSelected] = React.useState(COLORS[0]);

    function handleClick(color) {
        setSelected(color);
        onChange(color);
    }

    React.useEffect(() => {
        setSelected(selectedColor);
    }, [selectedColor]);

    return (
        <>
        {
            COLORS.map((color) => (
      		<Fab
                    key={color}
                    sx={{ mr: 0.5 }}
                    size="small"
                    style={{
                        backgroundColor: color,
                    }}
                    onClick={() => handleClick(color)}
                >
                    {selected === color && <CheckIcon />}
                </Fab>
            ))
        }
        </>
    )
}

export default ColorPicker;
