import React from 'react';
import {
    Avatar,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemAvatar,
} from "@mui/material";

import useMaterials from '../../hooks/useMaterials';

const MaterialsList = ({ onSelect = () => {} })  => {
    const {materials} = useMaterials();
    const items = materials
        .filter(m => ['image', 'video'].includes(m.type))
        .map(m => ({
            ...m,
            url: `/files/${m.contents[0]?.source?.filename}?namespace=${m.contents[0]?.source?.namespace}`
        }))

    const handleSelect = (material) => () => {
        onSelect(material)
    }

    return (
        <List>
            {
                items.map((material) => (
                    <ListItem key={material.id}>
                        <ListItemButton onClick={handleSelect(material)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={material.url} alt={material.name} width="100%"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={material.name} />
                        </ListItemButton>
                    </ListItem>
                ))
            }
        </List>
    )
}

export default MaterialsList;
