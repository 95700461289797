import React from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Divider} from "@mui/material";
import moment from 'moment'

import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import {menuItems} from "./data";

const NewRequestItem = ({item, onAction}) => {
    return (
        <React.Fragment>
            <Divider component="li"/>
            <ListItem sx={{px: 3, py: 1.25}}>
                <ListItemAvatar>
                    <Avatar alt={item.name} src={item.profile_pic}/>
                </ListItemAvatar>
                <ListItemText sx={{ flexBasis: '20%' }} primary={<Typography>{item.user_name}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography>{item.platform}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography>{item.access_token.slice(0, 6) + '......'}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography>{moment(item.created_at*1000).format('YYYY-MM-DD HH:mm')}</Typography>}
                />
                <ListItemText
                    sx={{flexGrow: 0}}
                    primary={
                        <JumboDdMenu
                            onClickCallback={(action) => onAction(action, item)}
                            menuItems={menuItems}
                        />
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};
/* Todo prop define */
export default NewRequestItem;
