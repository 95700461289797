import React from 'react';
import {
    Grid,
    Paper,
    TextField,
    Button,
    List,
    ListItem, 
    ListItemButton, 
    ListItemText,
    Typography,
    Checkbox,
    Stack,
    Backdrop,
    CircularProgress,
    alpha,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from 'moment';

import Select from '../../shared/Select';
import useTopics from '../../hooks/useTopics';
import searchService from '../../services/search';
import aiService from '../../services/ai';

const NewsItem = ({item, checked, onChecked}) => {
    return (
        <ListItem sx={{ p: 0 }}>
            <ListItemButton
                href={item.url}
                target="_blank"
                sx={{
                    p: 2,
                    transition: 'all 0.2s',
                    borderBottom: 1,
                    borderBottomColor: 'divider',
                    '&:hover': {
                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                        transform: 'translateY(-4px)',
                        borderBottomColor: 'transparent',
                    }
                }}
            >
                <ListItemText
                    primary={
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Checkbox
                                size='small'
                                edge="end"
                                onChange={e => onChecked(item.id, e.target.checked)}
                                checked={checked}
                                style={{ left: -12 }}
                            />
                            <Typography variant="h6" mb={.25}>
                                {item.title || item.content.slice(0, 30)}
                            </Typography>
                        </Stack>
                    }
                    secondary={
                        <Typography component={"div"}>
                            <Typography color={"text.secondary"} variant='body1' fontSize={12}
                                      mb={1}>{moment(item.tm*1000).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                            <Typography noWrap variant='body2'>{item.content}</Typography>
                        </Typography>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};

function AIGenerate({onGenerate = () => {}}) {
    const {t} = useTranslation();
    const {topics} = useTopics();
    const [isReady, setIsReady] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedTopic, setSelectedTopic] = React.useState(null);
    const [selectedNews, setSelectedNews] = React.useState('');
    const [topNews, setTopNews] = React.useState([]);
    const [action, setAction] = React.useState('');
    const [sentiment, setSentiment] = React.useState('');
    const [tone, setTone] = React.useState('');

    const actionOptions = React.useMemo(() => ([
      { name: '改寫', value: '改寫' },
      { name: '回覆', value: '回覆' },
      { name: '摘要', value: '摘要' },
    ]));
    const toneOptions = React.useMemo(() => ([
      { name: '網民', value: '網民' },
      { name: '專業', value: '專業' },
      { name: '直率', value: '直率' },
      { name: '自信', value: '自信' },
      { name: '友善', value: '友善' },
    ]));
    const sentimentOptions = React.useMemo(() => ([
      { name: '正向', value: '正向' },
      { name: '中立', value: '中立' },
      { name: '負面', value: '負面' },
    ]));

    const handleSelectNews = (id, checked) => {
        if (checked) {
            setSelectedNews(id);
        } else {
            setSelectedNews('');
        }
    };
    const handleSubmit = () => {
        const content = topNews.find(item => item.id === selectedNews)?.content;

        setIsLoading(true);
        aiService.reply(content, sentiment, tone)
            .then(res => {
                onGenerate(res.payload.reply.pop());
                setIsLoading(false);
            })
    }

    React.useEffect(() => {
        if (selectedTopic) {
            setIsLoading(true);
            setTopNews([]);
            setSelectedNews('');
            searchService.fetchTopNews(selectedTopic)
                .then(res => {
                    setTopNews(res);
                    setIsLoading(false);
                })
        }
    }, [selectedTopic]);
    React.useEffect(() => {
        if (selectedNews && action && sentiment && tone) {
            return setIsReady(true);
        }

        setIsReady(false);
    }, [selectedNews, action, sentiment, tone]);

  return (
    <Paper
      elevation={0}
      style={{ border: '1px solid #ccc', padding: 16, marginBottom: 24 }}
    >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={3}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <h3
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: -4,
                        background: '#fff',
                        padding: '0 8px',
                    }}
                >
                    {t('ai generated')}
                </h3>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Select
                        style={{ width: '100%' }}
                        label={t('topics')}
                        value={selectedTopic}
                        options={topics.map(t => ({ name: t.name, value: t.id }))}
                        variant="outlined"
                        onChange={e => setSelectedTopic(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <List disablePadding>
                    {
                        topNews.map((item, index) => (
                            <NewsItem
                                item={item}
                                key={index}
                                checked={item.id === selectedNews}
                                onChecked={handleSelectNews}
                            />
                        ))
                    }
                    </List>
                </Grid>
                    {
                        topNews.length > 0 &&
                        <React.Fragment>
                            <Grid item xs={4}>
                                <Select
                                    style={{ width: '100%' }}
                                    label={'請針對以上文章做'}
                                    options={actionOptions}
                                    variant="outlined"
                                    value={action}
                                    onChange={e => setAction(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    style={{ width: '100%' }}
                                    label={'以何種情緒'}
                                    options={sentimentOptions}
                                    variant="outlined"
                                    value={sentiment}
                                    onChange={e => setSentiment(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    style={{ width: '100%' }}
                                    label={'以何種語氣'}
                                    options={toneOptions}
                                    variant="outlined"
                                    value={tone}
                                    onChange={e => setTone(e.target.value)}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        style={{ float: 'right' }}
                        onClick={handleSubmit}
                        disabled={!isReady}
                    >
                        {t('generate')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
  );
}

export default AIGenerate;
