import creator from "./config";

async function reply(text, sentiment, tone) {
  return creator()({
    method: 'POST',
    url: '/jobs/reply',
    data: {
      text,
      sentiment,
      tone,
      n: 1,
    }
  })
  .then(_ => _.data)
}

export default { reply };
