import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function useTags() {
  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { data, mutate } = useSWR(`/users/${user.id}/tags`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/tags`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
  );

  function create(name, color) {
    const doc = {
      name: name,
      color,
      type: 0,
    }

    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/tags`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('id_token')
      },
      data: doc
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  function update(id, name, color) {
    const doc = {
      name,
      color,
    }

    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/tags/${id}`,
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('id_token')
      },
      data: doc
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  async function remove(id) {
    setIsLoading(true);
    return axios({
      url: `/api/v1/users/${user.id}/tags/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    })
    .then(_ => {
      setIsLoading(false);
      mutate()
    })
    .catch(e => {
      setIsLoading(false);
      throw e;
    })
  }

  return {
    tags: data?.data.filter(t => t.type === 0) || [],
    create,
    update,
    remove,
    isLoading,
  };
}
