import {
    Avatar,
    Card,
    CardHeader,
    CardContent,
    Stack,
    Button,
} from "@mui/material";
import {MoreHorizOutlined} from "@mui/icons-material";
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

function TwitterPreview({ content, file, token }) {
    return (
        <Card style={{ backgroundColor: '#000' }}>
            <CardHeader
                avatar={<Avatar alt="Apple" src="" />}
                title={
                    <div style={{ color: '#E7E9EA' }}>
                        {`${token.user_name}`}
                        <span style={{ color: '#71767B' }}> @123 · 1h</span>
                    </div>
                }
                subheader={
                    <Stack direction="row">
                        <p style={{ margin: 0 }}>{content.slice(0, 50)}</p>
                    </Stack>
                }
                action={<MoreHorizOutlined />}
            />
            <CardContent style={{ paddingTop: 0 }}>
                <Stack style={{ paddingLeft: 44 }}>
                    <p style={{ paddingLeft: 12, color: '#E7E9EA' }}>{content}</p>
                    {
                        file && file.type === 'image' &&
                        <div style={{ borderRadius: 12, overflow: 'hidden', marginLeft: 12 }}>
                            <img
                                width="100%"
                                alt="uploaded file"
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                            />
                        </div>
                    }
                    {
                        file && file.type === 'video' &&
                        <div style={{ borderRadius: 12, overflow: 'hidden', marginLeft: 12 }}>
                            <video
                                width="100%"
                                alt="uploaded file"
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                            />
                        </div>
                    }
                    <Stack direction="row" style={{ paddingLeft: 0, paddingTop: 16, display: 'flex', justifyContent: 'space-between' }}>
                        <Button style={{ color: "#71767B"}} startIcon={<ModeCommentOutlinedIcon/>}>0</Button>
                        <Button style={{ color: '#71767B' }} startIcon={<RepeatIcon/>}>0</Button>
                        <Button style={{ color: '#71767B' }} startIcon={<SignalCellularAltIcon />}>0</Button>
                        <Button style={{ color: '#71767B' }} startIcon={<FavoriteBorderOutlinedIcon />}>0</Button>
                        <Button style={{ color: '#71767B' }} startIcon={<FileUploadOutlinedIcon />}></Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default TwitterPreview;
