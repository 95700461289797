import creator from "./config";

const login = (email, password) => {
  return creator()({
    method: 'POST',
    url: '/auth/login',
    data: {
      email,
      password
    }
  })
}

export default { login };
