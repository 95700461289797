import React from 'react';
import {
    Card,
    CardContent,
} from "@mui/material";
import moment from 'moment';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import CalendarWrapper from "./CalendarWrapper";
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CustomToolbar = ({ onNavigate }) => {
  const handleTodayClick = () => {
    onNavigate('TODAY');
  };

  const handleBackClick = () => {
    onNavigate('PREV');
  };

  const handleNextClick = () => {
    onNavigate('NEXT');
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button className="rbc-btn" onClick={handleTodayClick}>
          Today
        </button>
        <button className="rbc-btn" onClick={handleBackClick}>
          Back
        </button>
        <button className="rbc-btn" onClick={handleNextClick}>
          Next
        </button>
      </span>
    </div>
  );
};

const CalendarBasic = ({ events = [], EventComponent }) => {
    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: '#fff',
            borderRadius: '5px',
            opacity: 0.8,
            color: '#fff',
            border: 'none',
            display: 'block',
            padding: '0px'
        };

        return {
            style,
        };
    };

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            step={60}
                            defaultDate={new Date()}
                            style={{height: '100vh'}}
                            className="custom-calendar"
                            eventPropGetter={eventStyleGetter}
                            components={{event: EventComponent, toolbar: CustomToolbar}}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>
        </React.Fragment>

    );
};

export default CalendarBasic;
