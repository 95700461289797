import React from "react";
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';
import DraftsIcon from '@mui/icons-material/DraftsOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import CollectionsIcon from '@mui/icons-material/CollectionsOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LabelIcon from '@mui/icons-material/LabelOutlined';

const menus = [
    {
        label: "sidebar.menu.dashboard",
        type: "section",
        children: [
            {
                uri: "/",
                label: "sidebar.menu.submenu.dashboard",
                type: "nav-item",
                icon: <AnalyticsIcon sx={{fontSize: 20}}/>
            },
        ]
    },
    {
        label: 'sidebar.menu.publish',
        type: "section",
        children: [
            {
                uri: "/publish",
                label: "sidebar.menu.submenu.publish",
                type: "nav-item",
                icon: <ArticleIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/schedule",
                label: "sidebar.menu.submenu.schedule",
                type: "nav-item",
                icon: <CalendarMonthIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/drafts",
                label: "sidebar.menu.submenu.drafts",
                type: "nav-item",
                icon: <DraftsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/approval",
                label: "sidebar.menu.submenu.approval",
                type: "nav-item",
                icon: <FactCheckIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/materials",
                label: "sidebar.menu.submenu.materials",
                type: "nav-item",
                icon: <CollectionsIcon sx={{fontSize: 20}}/>
            },
        ]
    },
    {
        label: 'sidebar.menu.settings',
        type: "section",
        children: [
            {
                uri: "/accounts",
                label: "sidebar.menu.submenu.accounts",
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/topics",
                label: "sidebar.menu.submenu.topics",
                type: "nav-item",
                icon: <NewspaperIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/tags",
                label: "sidebar.menu.submenu.tags",
                type: "nav-item",
                icon: <LabelIcon sx={{fontSize: 20}}/>
            },
        ]
    },
];

export default menus;
