import React from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import { useAuth } from "../../../AuthProvider";
import Facebook from "../../icons/Facebook";
import Instagram from "../../icons/Instagram";
import Twitter from "../../icons/Twitter";
import LinkedIn from "../../icons/LinkedIn";
import AccountsList from "./AccountsList";

const SocialAccountForm = () => {
    const {user} = useAuth();

    const authUrls = {
      facebook: `/authorize/2/facebook?user_id=${user.id}`,
      instagram: `/authorize/2/instagram?user_id=${user.id}`,
      twitter: `/authorize/1/twitter?user_id=${user.id}`,
      linkedin: `/authorize/2/linkedin?user_id=${user.id}`,
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={6}>
                <IconButton sx={{ borderRadius: 5 }} href={authUrls.facebook}>
                    <Facebook/>
                    <Div
                        sx={{
                            mt: 1,
                            ml: 2
                        }}
                    >
                        <Typography variant={"h5"} color={'text.secondary'}>Facebook</Typography>
                    </Div>
                </IconButton>
            </Grid>
            <Grid item xs={12} lg={6}>
                <IconButton sx={{ borderRadius: 5 }} href={authUrls.instagram}>
                    <Instagram/>
                    <Div
                        sx={{
                            mt: 1,
                            ml: 2
                        }}
                    >
                        <Typography variant={"h5"} color={'text.secondary'}>Instagram</Typography>
                    </Div>
                </IconButton>
            </Grid>
            <Grid item xs={12} lg={6}>
                <IconButton sx={{ borderRadius: 5 }} href={authUrls.linkedin}>
                    <LinkedIn/>
                    <Div
                        sx={{
                            mt: 1,
                            ml: 2
                        }}
                    >
                        <Typography variant={"h5"} color={'text.secondary'}>LinkedIn</Typography>
                    </Div>
                </IconButton>
            </Grid>
            <Grid item xs={12} lg={6}>
                <IconButton sx={{ borderRadius: 5 }} href={authUrls.twitter}>
                    <Twitter/>
                    <Div
                        sx={{
                            mt: 1,
                            ml: 2
                        }}
                    >
                        <Typography variant={"h5"} color={'text.secondary'}>Twitter</Typography>
                    </Div>
                </IconButton>
            </Grid>
        </Grid>
    )
}

const AccountsTable = ({accounts = []}) => {
    const {t} = useTranslation();
    const {showDialog} = useJumboDialog();

    const showAddAccountDialog = React.useCallback(() => {
        showDialog({
            title: "Add New Social Account",
            content: <SocialAccountForm/>,
        })
    }, [showDialog]);

    return (
        <JumboCardQuick
            title={t('widgets.title.accountsTable')}
            action={
                <IconButton onClick={showAddAccountDialog}>
                    <AddIcon/>
                </IconButton>
            }
            wrapperSx={{p: 0}}
        >
            {
                accounts.length === 0 
                    ? (
                        <Div
                            sx={{
                                p: 3
                            }}
                        >
                            <Typography variant={"h5"} color={'text.secondary'}>No accounts added</Typography>
                        </Div>
                    )
                    : <AccountsList accounts={accounts}/>
             }
        </JumboCardQuick>
    );
};

export default AccountsTable
