import React from 'react';
import {
    Badge,
    Stack,
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    TextField,
    IconButton,
    Tooltip,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/CollectionsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import Div from "@jumbo/shared/Div";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import usePublishAPI from '../../hooks/usePublish';
import useTokensAPI from '../../hooks/useTokens';
import useDraftsAPI from '../../hooks/useDrafts';
import { usePublish } from './provider';
import SettingDialog from './SettingDialog';
import TokenSelector from './TokenSelector';
import TagsSelector from './TagsSelector';
import AIGenerate from './AIGenerate';
import MaterialsList from './MaterialsList';
import { upload } from '../../services/files';

const Editor = () => {
    const {
        isAdding,
        selectTokens,
        selectedTokens,
        content,
        setContent,
        file,
        setFile,
        tags,
        setTags,
        publishAt,
        setPublishAt,
        scheduleType,
        setScheduleType,
        setIsAdding,
        reset,
    } = usePublish();
    const { tokens } = useTokensAPI();
    const { isLoading, create } = usePublishAPI();
    const { create: createDraft } = useDraftsAPI();
    const { showDialog, hideDialog } = useJumboDialog();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [readyToPublish, setReadyToPublish] = React.useState(false);

    React.useEffect(() => {
        setReadyToPublish(content && selectedTokens.length > 0)
    }, [content, selectedTokens])

    const handleSetContent = React.useCallback((value) => {
        setContent(value)
    }, [setContent])
    const handleTokensChange = React.useCallback((event) => {
        selectTokens(tokens.filter(t => event.target.value.includes(t.id)))
    }, [selectTokens, tokens])
    const handleTagsChange = React.useCallback((event) => {
        setTags(event.target.value)
    }, [setTags])
    const handleSaveSetting = setting => {
        setPublishAt(setting.publishAt)
        setScheduleType(setting.scheduleType)
        hideDialog()
    }
    const handleShowSettingsDialog = () => {
        showDialog({
            title: t('publishing time'),
            content: <SettingDialog
                publishAt={publishAt}
                scheduleType={scheduleType}
                onChange={handleSaveSetting}
            />
        })
    }
    const handleShowSaveDraft = () => {
        showDialog({
            title: t('save as draft'),
            variant: 'confirm',
            onYes: handleSaveDraft,
        })
    }
    const handleSaveDraft = () => {
        const platforms = selectedTokens.map(t => ({
            token_id: t.id,
            type: t.platform,
            user_name: t.user_name,
        }))
        createDraft(content, platforms)
            .then(_ => {
                hideDialog()
                reset()
                onCancel()
                navigate('/drafts')
            })
            .catch(_ => hideDialog())
    }
    const handlePublish = () => {
        create({
            content,
            file,
            platforms: selectedTokens.map(t => ({ token_id: t.id, type: t.platform })),
            publishAt,
            scheduleType,
            tags,
        })
            .then(_ => {
                reset()
                showDialog({
                    title: t('success'),
                    onClose: onCancel,
                })
            })
            .catch(e => {
                showDialog({
                    title: t('fail'),
                    content: e.message,
                })
            })
    }
    const handleUpload = (file, type) => {
        const formData = new FormData();
        formData.append("file", file)

        upload(formData, "chatpr")
          .then(data => {
            setFile({ ...data.payload, type })
          })
    }
    const onCancel = React.useCallback(() => {
        setIsAdding(false)
    }, [setIsAdding])
    const handleShowMaterials = () => {
        showDialog({
            title: t('materials'),
            content: <MaterialsList
                onSelect={handleSelectMaterial}
            />
        })
    }
    const handleSelectMaterial = React.useCallback((material) => {
        setFile({ ...material?.contents[0]?.source, type: material.type })
        hideDialog()
    }, [setFile, hideDialog])
    const handleClearFile = React.useCallback(() => {
        setFile(null)
    }, [setFile])

    if (!isAdding) return null
    return (
        <Card>
            <CardHeader
                title={
                    <Stack sx={{gap: 2}}>
                        <TokenSelector
                            tokens={tokens}
                            selectedTokens={selectedTokens.map(t => t.id)}
                            onChange={handleTokensChange}
                        />
                        <TagsSelector
                            value={tags}
                            onChange={handleTagsChange}
                        />
                    </Stack>
                }
                action={
                    <Stack direction={"row"} sx={{backgroundColor: 'transparent'}}>
                        <Tooltip title={t('publishing time')}>
                            <IconButton onClick={handleShowSettingsDialog}>
                                <AccessTimeIcon/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
            />
            <CardContent>
                <Stack sx={{ gap: 2 }}>
                    <AIGenerate
                        onGenerate={handleSetContent}
                    />
                    <TextField
                        multiline
                        rows={8}
                        sx={{
                            width: '100%',
                        }}
                        value={content}
                        onChange={e => handleSetContent(e.target.value)}
                        placeholder={t('write something')}
                    />
                    {
                        file && file.type === 'image' &&
                        <Badge
                            badgeContent={<CloseIcon/>}
                            color='primary'
                            style={{ width: '50%', cursor: 'pointer' }}
                            onClick={handleClearFile}
                        >
                            <img
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                                alt="uploaded"
                                width="100%"
                            />
                        </Badge>
                    }
                    {
                        file && file.type === 'video' &&
                        <Badge
                            badgeContent={<CloseIcon/>}
                            color='primary'
                            style={{ width: '50%', cursor: 'pointer' }}
                            onClick={handleClearFile}
                        >
                            <video
                                src={`/files/${file.filename}?namespace=${file.namespace}`}
                                alt="uploaded"
                                width="100%"
                            />
                        </Badge>
                    }
                </Stack>
            </CardContent>
            <CardActions sx={{py: theme => theme.spacing(1.5), display: 'flex', justifyContent: 'space-between'}}>
                <Div>
                    <Tooltip title={t('upload image')}>
                        <IconButton color="primary" aria-label="upload image" component="label">
                            <input
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={e => handleUpload(e.target.files[0], 'image')}
                            />
                            <PhotoCamera />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('upload video')}>
                        <IconButton color="primary" aria-label="upload video" component="label">
                            <input 
                                hidden
                                accept="video/*"
                                type="file"
                                onChange={e => handleUpload(e.target.files[0], 'video')}
                            />
                            <VideocamIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('select from materials')}>
                        <IconButton color="primary" component="label" onClick={handleShowMaterials}>
                            <CollectionsIcon />
                        </IconButton>
                    </Tooltip>
                </Div>
                <Div>
                <Button onClick={onCancel} sx={{ mr: 1 }}>Cancel</Button>
                <Button
                    onClick={handleShowSaveDraft}
                    variant={"contained"}
                    sx={{ mr: 1 }}
                    disabled={!readyToPublish}
                >
                    {t('save as draft')}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={handlePublish}
                    disabled={!readyToPublish}
                >
                    Publish
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Div>
            </CardActions>
        </Card>
    );
};

export default Editor;
