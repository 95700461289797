import React from 'react';
import {
    Stack,
    Avatar,
} from "@mui/material";

import FacebookIcon from '../../../shared/icons/Facebook'
import InstagramIcon from '../../../shared/icons/Instagram'
import TwitterIcon from '../../../shared/icons/Twitter'
import LinkedInIcon from '../../../shared/icons/LinkedIn'

import {Twitter} from "@mui/icons-material";

const PlatformAvatarGroup = ({platform}) => {
    const icons = {
        facebook: <FacebookIcon fontSize={32}/>,
        instagram: <InstagramIcon fontSize={32}/>,
        twitter: <TwitterIcon fontSize={32}/>,
        linkedin: <LinkedInIcon fontSize={32}/>,
    }

    return (
        <Stack direction={"row"} sx={{ overflow: 'hidden' }}>
            { icons[platform] }
        </Stack>
    );
};
/* Todo prop define */
export default PlatformAvatarGroup;
