import React from 'react';
import {Grid} from "@mui/material";

import AccountsSum from "../../shared/widgets/AccountsSum";
import AccountsTable from "../../shared/widgets/AccountsTable";

import useTokens from '../../hooks/useTokens';

const Accounts = () => {
    const { tokens } = useTokens();

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={2}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={6} lg={12}>
                        <AccountsSum
                            platform="facebook"
                            count={tokens.filter(acc => acc.platform === 'facebook').length}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <AccountsSum
                            platform="instagram"
                            count={tokens.filter(acc => acc.platform === 'instagram').length}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <AccountsSum
                            platform="twitter"
                            count={tokens.filter(acc => acc.platform === 'twitter').length}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <AccountsSum
                            platform="linkedin"
                            count={tokens.filter(acc => acc.platform === 'linkedin').length}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={10}>
                <AccountsTable accounts={tokens}/>
            </Grid>
        </Grid>
    );
};

export default Accounts;
