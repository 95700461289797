import {
    Card,
    CardHeader,
    CardContent,
} from "@mui/material";

function FacebookPreview({ content }) {
    return (
        <Card>
            <CardHeader title="Linkedin Preview"></CardHeader>
            <CardContent>
            {
                content.split('\n').map((item, i) => {
                    return <p key={i}>{item}</p>
                })
            }
            </CardContent>
        </Card>
    )
}

export default FacebookPreview;
