import React from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment'

import JumboDdMenu from "@jumbo/components/JumboDdMenu";

const menuItems = [
    {
        icon: <EditIcon size={20}/>,
        title: "Edit",
        value: "EDIT"
    },
    {
        icon: <DeleteIcon size={20}/>,
        title: "DELETE",
        value: "DELETE"
    },
];

const Item = ({item, onAction}) => {
    return (
        <React.Fragment>
            <Divider component="li"/>
            <ListItem sx={{px: 3, py: 1.25}}>
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={<Typography>{moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm')}</Typography>}
                />
                <ListItemText
                    sx={{ flexBasis: '20%' }}
                    primary={
                        <Typography variant='h5' mb={.5}>
                            { item.content }
                        </Typography>
                    }
                />
                <ListItemText
                    sx={{flexGrow: 0}}
                    primary={
                        <JumboDdMenu
                            onClickCallback={(action) => onAction(action, item)}
                            menuItems={menuItems}
                        />
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};
/* Todo prop define */
export default Item;
