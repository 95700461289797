import React from 'react';
import {Card, CardContent, Typography, Button, Avatar} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import {alpha} from "@mui/material/styles";
import * as yup from 'yup';
import {Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {useAuth} from "../../AuthProvider";
import google from "../../../google.svg";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Login = () => {
    const { login } = useAuth();

    const onSignIn = (email, password) => {
      login(email, password)
    };

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Div sx={{mb: 3, display: 'inline-flex'}}>
                <Link
                    href="#"
                    underline="none"
                    sx={{display: 'inline-flex'}}
                >
                    <img src={`${ASSET_IMAGES}/logo.png`} alt="ChatPR"/>
                </Link>
            </Div>
            <Card sx={{maxWidth: '100%', width: 360, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/colin-watts.jpg`}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}
                    >
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}
                        >
                            Sign In
                        </Typography>
                    </Div>
                </Div>
                <CardContent sx={{pt: 0}}>

                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left', marginTop: 24}} noValidate autoComplete='off'>
                                <Div sx={{mb: 3, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                    />
                                </Div>
                                <Div sx={{mb: 2, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                    />
                                </Div>
                                {/*
                                <Typography
                                    textAlign={"right"}
                                    variant={"body1"}
                                >
                                    <Link underline="none" href="#">Forgot your password?</Link>
                                </Typography>
                                <Div sx={{mb: 1}}>
                                    <FormControlLabel control={<Checkbox/>} label="Remember me"/>
                                </Div>
                                */}
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Login</LoadingButton>
                                {/*
                                <Typography textAlign={"center"} variant={"body1"} mb={1}>Don't have an account?
                                    <Link underline="none" href="#">Sign up now</Link>
                                </Typography>
                                */}
                            </Form>
                        )}
                    </Formik>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                            mb: 3,
                            color: '#666',
                            bgcolor: '#fff',
                            '&:hover': {
                                bgcolor: '#fff',
                            }
                        }}
                        href="/auth/google"
                        startIcon={
                            <Avatar style={{ width: 20, height: 20, marginRight: 4 }} src={google} />
                        }
                    >Continue with Google</Button>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;
