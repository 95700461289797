import React from 'react';
import {List} from "@mui/material";
import Item from "./Item";

const TagsList = ({tags = [], onAction = () => {}}) => {
    return (
        <List disablePadding>
            {
                tags.map((item, index) => (
                    <Item item={item} key={index} onAction={onAction}/>
                ))
            }
        </List>
    );
};
/* Todo prop define */
export default TagsList;
