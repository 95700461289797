import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

import { useAuth } from '../AuthProvider';

export default function useDashboard(id) {
  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { data, error, mutate } = useSWR(`/users/${user.id}/tokens/${id}/dashboard`, (url) =>
    axios({
      method: 'GET',
      url: `/api/v1/users/${user.id}/tokens/${id}/dashboard`,
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    }),
    {shouldRetryOnError: false}
  );

  return {
    data: data?.data || {},
    error,
    isLoading,
  };
}
